import Pagination from 'vue-pagination-2';

export default {
  data: () => ({
    page: 1,
    total: 0,
    options: {
      texts: {
        count: 'de {from} à {to} d\'un total de {count} éléments|{count} éléments|1 élément',
      },
    },
    filter: {},
  }),
  components: {
    Pagination,
  },
  methods: {
    async search() {
      this.page = 1;
      const { total, data } = await this.service.indexByFilter(this.filter, this.page);
      this.total = total;
      this.objects = data;
    },
    async getFirst() {
      const { total, data } = await this.service.indexPaginated(this.page);
      this.total = total;
      this.objects = data;
    },
    async getNext() {
      const { data } = await this.service.indexByFilter(this.filter, this.page);
      this.objects = data;
    },
  },
};
